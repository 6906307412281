<script>
import OperationHistoryTable from '@/components/Reservation/OperationHistoryTable'
export default {
  name: 'OperationHistoryDetail',
  components: { OperationHistoryTable },
  data: () => ({
    customer: null,
    tableHeight: 500,
  }),
  computed: {
    customerId() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.fetchData()
    this.fixHeight()
  },
  methods: {
    async fetchData() {
      this.customer = await this.$api.customers(this.customerId).get()
    },
    fixHeight() {
      const rect = this.$refs.tableContainer.getBoundingClientRect()
      if (rect) {
        const footRect = this.$refs.bottom.getBoundingClientRect()

        let h = window.innerHeight - rect.top - footRect.height
        if (h > 300) this.tableHeight = h + 'px'
      }
    },
  },
}
</script>

<template>
  <v-card flat>
    <v-card-text>
      施術履歴
      <template v-if="customer">
        {{ customer.account }}
        {{ customer.familyNameKana }}
        {{ customer.givenNameKana }}
      </template>
    </v-card-text>
    <div ref="tableContainer" />

    <OperationHistoryTable :customer="customerId" :height="tableHeight" />
    <div ref="bottom" />
  </v-card>
</template>
